import React from 'react';


export default function Hakkimizda() {
  return (
    <div className="container mx-auto px-4 py-32">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
        {/* Görsel alanı */}
        <div className="flex justify-center">
          <img
            src="/images/AnaSayfa.jpg" // Görsel yolunu kendi dosya yapınıza göre düzenleyin
            alt="About Us"
            className="w-full h-full max-h-max max-w-6xl rounded-lg shadow-lg " 
          />
        </div>
        {/* Metin ve içerik alanı */}
        <div className="space-y-8">
          {/* <h2 className="text-4xl font-bold text-blue-400">Hakkımızda</h2> */}

          <p className="text-gray-700 leading-relaxed font-bold">
          MEZUNİYET ÖNCESİ TIP EĞİTİMİNDE ‘OLAĞAN DIŞI DURUMLARDA TEMEL SAĞLIK HİZMETLERİ EĞİTİMİ’ PROGRAMI GELİŞTİRME
          </p>
          <ul className="list-none space-y-6">
          <li className="flex items-start">
             
             <p className="text-gray-700">
             Deprem, pandemi, yangın, sel, savaş/çatışma ve benzeri olağan dışı durumların (ODD) sıklıkla yaşanıyor olması, hekimlerin bu durumlarda ne tip yetkinliklere/yeterliklere sahip olmaları gerektiği konusunu gündeme getirmektedir. “Çoklu Krizler Çağı” olarak adlandırılan içinden geçtiğimiz dönemde sıklıkla karşılaşılan ODD’ler, hekimlerin olağan çalışma koşullarında ihtiyaç duyduklarından farklı bilgi, beceri ve tutumlara sahip olmalarını zorunlu kılmaktadır. Buna rağmen, ODD’lerde temel sağlık hizmetlerinin yürütülebilmesi için gereksinim duyulan yetkinlikler hâlihazırda tıp fakültelerinin zorunlu müfredatları arasında yer almamakta, bu ihtiyaç ağırlıkla mezuniyet sonrasında gönüllülüğe dayanan kurslarda giderilmeye çalışılmaktadır. Varolan eğitim uygulamaları da bilimsel analizlerle ortaya çıkarılmış olmaktan çok sezgisel görünmektedir.
             </p>
           </li>
            <li className="flex items-start">
             
              <p className="text-gray-700">
              Tasarım temelli bir araştırma modeline dayanan bu proje, sözü geçen eğitim açığının giderilmesine yönelik bir eğitim programı geliştirmeyi amaçlamaktadır. Öncelikle sorun merkezli sistematik ihtiyaç analizi yapılacak, ardından bilimsel zeminde bir eğitim programı tasarlanacak, program uygulanacak ve değerlendirilecektir. Uygulamaya dönük bilimsel bir kavramsal çerçeve üretmeyi ve bu kapsamda yapılacak eğitim uygulamalarına zemin oluşturmayı hedefleyen projenin ilk aşamasında yer alan sistematik ihtiyaç analizinde literatür taraması, bilimsel toplantılar ve bir araştırma yapılması ile Delphi tekniği aracılığıyla uzman görüşü oluşturulması planlanmaktadır. İlk aşamanın sonunda ODD bağlamındaki hekimlik yetkinlikleri/yeterlikleri tanımlanacaktır. 
              </p>
            </li>
            <li className="flex items-start">
             
              <p className="text-gray-700">
              İkinci aşamada, bu yetkinlik/yeterliklerin tıp fakültesi öğrencilerine kazandırılmasını amaçlayan eğitim programı tasarımı yer almaktadır. Bu aşamada Ulusal Çekirdek Eğitim Programı (UÇEP) 2020 ile Türkiye ve dünyadan konuyla ilgili seçilmiş eğitim uygulamaları karşılaştırmalı olarak analiz edilecek, sorun alanları tespit edilecek ve bir tasarım çalıştayı düzenlenecektir. 
              </p>
            </li>
            <li className="flex items-start">
             
              <p className="text-gray-700">
              Üçüncü aşamada, programın pilot uygulaması ve aksayan yönlerinin tespit edilerek giderilmesi, dördüncü aşamada seçilmiş tıp fakültelerinde uygulamalar yapılması ve bunların değerlendirilmesi bulunmaktadır. Son aşamada programın tıp eğitimi kamuoyu ile paylaşılması vardır. Böylece hem program hakkında bilgilendirme yapılması hem de söz konusu eğitim ihtiyacı hakkında farkındalık oluşturulması düşünülmektedir. 
              </p>
            </li>
            <li className="flex items-start">
             
              <p className="text-gray-700">
              Proje, Çukurova Üniversitesi Tıp Fakültesi’nde yapılmış çalışmaların devamıdır. Sonuçların, gelecekte yaşanacak felaketler karşısında toplumların başetme kapasitelerinin merkezi bir unsuru olan hekimlerin ODD yetkinliklerinin geliştirilmesine somut bir düşünsel ve eğitimsel katkı sunması ve bu ihtiyaç doğrultusunda tıp fakültelerinde farkındalık oluşturması beklenmektedir.
              </p>
            </li>
          </ul>
        </div>
      </div>
  
    </div>
  );
}

import { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const navigation = [
    { name: 'Ana Sayfa', href: '/', current: false },
    { name: 'Hakkımızda', href: '/Hakkimizda', current: false, hasDropdown: true, dropdownItems: [
        { name: 'Proje Özeti', href: '/Hakkimizda/ProjeOzeti' },
        { name: 'Proje Ekibimiz', href: '/Hakkimizda/ProjeEkibimiz' }
    ]},
    { name: 'Bağlantılar', href: '/Baglantılar', current: false },
    { name: 'Arşiv', href: '/Arsiv', current: false },
    { name: 'Duyurular', href: '/Duyurular', current: false },
    { name: 'İletişim', href: '/İletisim', current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function DropdownMenu({ dropdownItems, isMobile }) {
    return (
        <div className={`${isMobile ? "mt-1" : "absolute left-0 top-full mt-1"} w-full sm:w-48 bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50 rounded-md`}>
            <div className="py-1">
                {dropdownItems.map((subItem) => (
                    <a
                        key={subItem.name}
                        href={subItem.href}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        {subItem.name}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default function Navbar() {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDropdownClick = (index) => {
        setDropdownOpen(dropdownOpen === index ? null : index);
    };

    return (
        <Disclosure as="nav" className="bg-[#FFFFFF] drop-shadow-2xl fixed w-full z-50">
            <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    {isMobile && (
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <Disclosure.Button className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-700 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700">
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                                <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                            </Disclosure.Button>
                        </div>
                    )}
                    <div className={`flex flex-1 items-center ${isMobile ? 'justify-center py-4' : 'justify-evenly'} sm:items-center`}>
                        <a href='/' className="flex items-center">
                            <img src="/Logo.png" alt="Logo" className="w-10 h-10 mr-3" />
                            <span className="text-xl font-semibold text-gray-900">Krizler ve Hekimlik</span>
                        </a>                
                        {!isMobile && (
                            <div className="hidden sm:ml-6 sm:block">
                                <div className="flex space-x-4">
                                    {navigation.map((item, index) => (
                                        <div key={item.name} className="relative">
                                            <a
                                                href={item.href}
                                                onClick={(e) => {
                                                    if (item.hasDropdown) {
                                                        e.preventDefault();
                                                        handleDropdownClick(index);
                                                    }
                                                }}
                                                aria-current={item.current ? 'page' : undefined}
                                                className={classNames(
                                                    item.current ? 'bg-gray-900 text-gray-700' : 'text-gray-900 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-lg font-medium flex items-center'
                                                )}
                                            >
                                                {item.name}
                                                {item.hasDropdown && (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className={`ml-2 h-4 w-4 transition-transform duration-200 ${dropdownOpen === index ? 'rotate-180' : 'rotate-0'}`}
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                )}
                                            </a>
                                            {item.hasDropdown && dropdownOpen === index && (
                                                <DropdownMenu dropdownItems={item.dropdownItems} isMobile={isMobile} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="absolute inset-y-0 right-0 items-center gap-3 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 lg:flex hidden">
                        {[faYoutube, faFacebook, faInstagram].map((icon, index) => (
                            <button key={index} type="button" className="rounded-full bg-[#FFFFFF] p-1 text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800">
                                <FontAwesomeIcon icon={icon} />
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {isMobile && (
                <Disclosure.Panel className="sm:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2">
                        {navigation.map((item, index) => (
                            <div key={item.name} className="flex flex-col">
                                <a
                                    href={item.href}
                                    onClick={(e) => {
                                        if (item.hasDropdown) {
                                            e.preventDefault();
                                            handleDropdownClick(index);
                                        }
                                    }}
                                    className={classNames(
                                        item.current ? 'bg-gray-900 text-gray-700' : 'text-gray-700 hover:bg-gray-700 hover:text-white',
                                        'w-full flex items-center justify-between rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                >
                                    {item.name}
                                    {item.hasDropdown && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`ml-2 h-4 w-4 transition-transform duration-200 ${dropdownOpen === index ? 'rotate-180' : 'rotate-0'}`}
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                        </svg>
                                    )}
                                </a>
                                {item.hasDropdown && dropdownOpen === index && (
                                    <DropdownMenu dropdownItems={item.dropdownItems} isMobile={isMobile} />
                                )}
                            </div>
                        ))}
                    </div>
                </Disclosure.Panel>
            )}
        </Disclosure>
    );
}

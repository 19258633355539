import { ThemeProvider } from "@material-tailwind/react";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Example from './components/Navbar';
import './App.css';
import Anasayfa from './pages/Anasayfa';
import HekimFooter from './components/Footer';
import Hakkimizda from "./pages/Hakkimizda";
import Arsiv from "./pages/Arsiv";
import Duyurular from "./pages/Duyurular";
import İletisim from "./pages/İletisim";
import ProjeEkibimiz from "./pages/ProjeEkibimiz";
import Baglantılar from "./pages/Kaynakca";
import DuyuruDetay from "./pages/DuyuruDetay"; // Import the new component
import BackToTopButton from "./components/BackToTopButton";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="app-container">
          <Example />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Anasayfa />} />
              <Route path="/Hakkimizda/ProjeOzeti" element={<Hakkimizda />} />
              {/* <Route path="/Hakkimizda/ProjeHakkında" element={<ProjeHakkinda/>} /> */}
              <Route path="/Baglantılar" element={<Baglantılar />} />
              <Route path="/Arsiv" element={<Arsiv />} />
              <Route path="/Duyurular" element={<Duyurular />} />
              <Route path="/Duyurular/:linkName" element={<DuyuruDetay />} /> {/* Add the new route */}
              <Route path="/İletisim" element={<İletisim />} />
              <Route path="/Hakkimizda/ProjeEkibimiz" element={<ProjeEkibimiz />} />
            </Routes>
          </div>
          <HekimFooter />
          <BackToTopButton />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
import React, { useState, useEffect } from 'react';

export default function Arsiv() {
  const [arsiv, setArsiv] = useState([]);
  const [sortedArsiv, setSortedArsiv] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [sortOrder, setSortOrder] = useState('desc');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/Arsiv.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setError('Yapım Aşamasında...');
        } else {
          setArsiv(data);
          setSortedArsiv(sortByDate(data, 'desc'));
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Veriler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      });
  }, []);
  //Arsiv.json dosyasını çekmek için useEffect kullanıyoruz ve yorum satırından çıkarıldıktan sonra aktif hale getirilecek
  const sortByDate = (data, order) => {
    return [...data].sort((a, b) => {
      if (order === 'asc') {
        return new Date(a.date) - new Date(b.date);
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
    setSortedArsiv(sortByDate(arsiv, order));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedArsiv.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedArsiv.length / itemsPerPage);

  return (
    <div className="container mx-auto px-4 py-32 bg-gray-50">
      <h1 className="text-4xl font-bold text-blue-900 mb-10 text-center">Arşiv</h1>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-semibold text-blue-800">Tarihe Göre Sıralama</h2>
        <select
          value={sortOrder}
          onChange={(e) => handleSortChange(e.target.value)}
          className="border border-gray-300 rounded p-2"
        >
          <option value="desc">En Yeni</option>
          <option value="asc">En Eski</option>
        </select>
      </div>

      <div className="space-y-8">
        {currentItems.map((duyuru, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md border border-gray-200 flex space-x-4"
          >
            <div className="flex-shrink-0">
              <img
                src={process.env.PUBLIC_URL + duyuru.image}
                alt={duyuru.title}
                className="w-full h-auto max-w-[150px] max-h-[250px] object-cover rounded"
              />
            </div>
            <div className="flex-grow">
              <h3 className="text-2xl font-semibold text-gray-800">{duyuru.title}</h3>
              <p className="text-gray-500">{new Date(duyuru.date).toLocaleDateString()}</p>
              <p className="text-gray-700">{duyuru.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div> {error ? ( <p>{error}</p> ) : ( <ul> {sortedArsiv.map((item, index) => ( <li key={index}>{item.name}</li> ))} </ul> )} </div>

      {totalPages > 1 && (
        <div className="flex justify-center mt-10">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`px-4 py-2 mx-1 rounded ${
                currentPage === i + 1 ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'
              } hover:bg-blue-700 transition`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';

export default function Baglantılar() {
  const [kaynaklar, setKaynaklar] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredKaynaklar, setFilteredKaynaklar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState('all');
  const itemsPerPage = 10;
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/Kaynaklar.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setError('Yapım Aşamasında...');
        } else {
          setKaynaklar(data);
          setFilteredKaynaklar(data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Veriler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      });
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    filterData(value, selectedType);
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    setSelectedType(value);
    filterData(searchTerm, value);
  };

  const filterData = (searchTerm, type) => {
    let filtered = kaynaklar;

    if (searchTerm) {
      filtered = filtered.filter((kaynak) =>
        kaynak.title.toLowerCase().includes(searchTerm)
      );
    }

    if (type !== 'all') {
      filtered = filtered.filter((kaynak) => kaynak.type === type);
    }

    setFilteredKaynaklar(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredKaynaklar.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredKaynaklar.length / itemsPerPage);

  return (
    <div className="container mx-auto px-4 py-10 bg-gray-50">
      <h1 className="text-2xl sm:text-4xl font-bold text-blue-900 mb-8 text-center">Bağlantılar</h1>

      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl sm:text-2xl font-semibold text-blue-800 mb-4">Filtreleme ve Arama</h2>

        <label className="block text-gray-700 mb-2 font-medium" htmlFor="filter">
          Kaynak Türü
        </label>
        <select
          id="filter"
          value={selectedType}
          onChange={handleTypeChange}
          className="border border-gray-300 rounded p-2 mb-4 w-full"
        >
          <option value="all">Hepsi</option>
          <option value="website">Web Sitesi</option>
          <option value="video">Video</option>
          <option value="book">Kitap</option>
          <option value="Makale">Makale</option>
          <option value="other">Diğerleri</option>
        </select>

        <div className="flex flex-col sm:flex-row items-center">
          <input
            type="text"
            placeholder="Kaynakça ara..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="border border-gray-300 rounded p-2 w-full sm:mr-2 mb-2 sm:mb-0"
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition w-full sm:w-auto">
            Ara
          </button>
        </div>
      </div>

      <div className="space-y-6">
        {currentItems.map((kaynak, index) => (
          <div
            key={index}
            className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-gray-200"
          >
            {/* Layout based on kaynak type */}
            {kaynak.type === 'website' ? (
              <div className="flex flex-col items-start p-4 max-h-48 overflow-y-auto w-full pt-2 space-y-1">
                <div className="flex items-center mb-2">
                  <img
                    src={kaynak.image}
                    alt="Website icon"
                    className="w-8 h-8 mr-2"
                  />
                  <h3 className="text-lg font-semibold text-blue-900 ">
                    {kaynak.title}
                  </h3>
                </div>
                {/* List of links with consistent padding */}
                <div className="max-h-40 overflow-y-auto w-full pt-2 space-y-1">
                  <a
                    href={kaynak.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block text-blue-600 hover:text-blue-800 transition text-sm p-2 bg-gray-50 rounded break-words"
                    style={{
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      padding: '8px', // consistent padding for all sides
                    }}
                  >
                    {kaynak.link}
                  </a>
                </div>
              </div>
            ) : kaynak.type === 'video' ? (
              <div className="flex flex-col items-center sm:items-start">
                <img
                  src={kaynak.image}
                  alt="Video icon"
                  className="w-full h-auto sm:w-32 mb-4"
                />
                <h3 className="text-lg font-semibold text-red-700 mb-2 text-center sm:text-left">
                  {kaynak.title}
                </h3>
                <a
                  href={kaynak.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red-500 hover:text-red-700 transition text-sm text-center sm:text-left"
                >
                  İzle
                </a>
              </div>
            ) : kaynak.type === 'book' ? (
              <div className="flex flex-col items-center sm:items-start">
                <img
                  src={kaynak.image}
                  alt="Book icon"
                  className="w-20 h-28 mb-4"
                />
                <h3 className="text-lg font-semibold text-green-900 mb-2 text-center sm:text-left">
                  {kaynak.title}
                </h3>
                <p className="text-sm text-gray-600 text-center sm:text-left">
                  {kaynak.description}
                </p>
                {kaynak.author && (
                  <p className="text-gray-500 mb-2 text-center sm:text-left">
                    Yazar: {kaynak.author}
                  </p>
                )}
              </div>
            ) : kaynak.type === 'Makale' ? (
              <div className="flex flex-col items-center sm:items-start">
                <h3 className="text-lg font-semibold text-gary-900 mb-2 text-center sm:text-left">
                  {kaynak.title}
                </h3>
                <p className="text-sm text-gray-600 mb-2 text-center sm:text-left">
                  {kaynak.description}
                </p>
            
              </div>
            ) : (
              <div className="flex flex-col items-center sm:items-start">
                <img
                  src={kaynak.image}
                  alt="Other content icon"
                  className="w-12 h-12 mb-4"
                />
                <h3 className="text-lg font-semibold text-gray-700 mb-2 text-center sm:text-left">
                  {kaynak.title}
                </h3>
                <a
                  href={kaynak.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-800 transition text-sm text-center sm:text-left"
                >
                  Diğer İçeriğe Git
                </a>
              </div>
            )}
          </div>
        ))}
      </div>

      <div>
        {error ? (
          <p>{error}</p>
        ) : (
          <ul>
            {filteredKaynaklar.map((item, index) => (
              <li key={index}>{item.name}</li>
            ))}
          </ul>
        )}
      </div>
      {totalPages > 1 && (
  <div className="flex justify-center mt-10 overflow-x-auto">
    <div className="flex space-x-2 px-4">
      {/* First Page */}
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 sm:px-4 sm:py-2 rounded ${
          currentPage === 1 ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'
        } hover:bg-blue-700 transition`}
      >
        1
      </button>

      {/* Show pages around the current page */}
      {currentPage > 3 && <span className="px-3 py-1">...</span>}
      {Array.from({ length: 3 }, (_, i) => currentPage - 1 + i)
        .filter(page => page > 1 && page < totalPages)
        .map(page => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 sm:px-4 sm:py-2 rounded ${
              currentPage === page ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'
            } hover:bg-blue-700 transition`}
          >
            {page}
          </button>
        ))
      }
      {currentPage < totalPages - 2 && <span className="px-3 py-1">...</span>}

      {/* Last Page */}
      {totalPages > 1 && (
        <button
          onClick={() => handlePageChange(totalPages)}
          className={`px-3 py-1 sm:px-4 sm:py-2 rounded ${
            currentPage === totalPages ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'
          } hover:bg-blue-700 transition`}
        >
          {totalPages}
        </button>
      )}
    </div>
  </div>
)}

    </div>
  );
}

import React from 'react';

export default function HekimFooter() {
  return (
    <div className="bg-[#FFFFFF] drop-shadow-2xl w-full">
      <footer className="container mx-auto flex flex-col md:flex-row justify-between items-center py-6 px-4 space-y-4 md:space-y-0">
        
        {/* Logo Bölümü */}
        <div className="flex items-center justify-center md:justify-start mb-4 md:mb-0">
          <a href='/' className="flex items-center mb-4 md:mb-0">
            <img src="/Logo.png" alt="Logo" className="w-10 h-10 mr-3" />
            <span className="text-xl font-semibold text-gray-900">Krizler ve Hekimlik</span>
          </a>
        </div>

        {/* TÜBİTAK Bilgilendirme Yazısı ve Ek Bilgi */}
        <div className="flex flex-col text-center md:text-left items-center justify-center md:items-start">
          <h1 className="text-gray-600 text-sm md:text-base">
            TÜBİTAK 1001- Bilimsel ve Teknolojik Araştırma Projelerini Destekleme Programı tarafından desteklenmektedir.
          </h1>
          <p className="text-gray-600 text-sm text-center md:text-left mt-1 md:mt-2">
            Bu sayfa <a href='https://mertbatut.com.tr' target='_blank' rel='noopener noreferrer' className="text-blue-500 hover:underline">MERT BATUT</a> tarafından yapılmıştır.
          </p>
        </div>
      </footer>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Duyurular() {
  const [duyurular, setDuyurular] = useState([]);
  const [sortedDuyurular, setSortedDuyurular] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [sortOrder, setSortOrder] = useState('desc');
  const [setError] = useState(null);

  // JSON dosyasını çekmek için useEffect kullanıyoruz
  useEffect(() => {
    fetch('/Duyurular.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setError('Yapım Aşamasında...');
        } else {
          setDuyurular(data);
          setSortedDuyurular(sortByDate(data, sortOrder));
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Veriler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      });
  });

  // Tarihe göre sıralama fonksiyonu
  const sortByDate = (data, order) => {
    return [...data].sort((a, b) => {
      if (order === 'asc') {
        return new Date(a.date) - new Date(b.date);
      } else {
        return new Date(b.date) - new Date(a.date);
      }
    });
  };

  // Sıralama değiştirildiğinde verileri güncelle
  const handleSortChange = (order) => {
    setSortOrder(order);
    setSortedDuyurular(sortByDate(duyurular, order));
    setCurrentPage(1); // Sıralama değiştiğinde sayfayı başa al
  };

  // Sayfa değiştirme fonksiyonu
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Sayfalandırma için verileri dilimle
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedDuyurular.slice(indexOfFirstItem, indexOfLastItem);

  // Sayfa numaralarını hesapla
  const totalPages = Math.ceil(sortedDuyurular.length / itemsPerPage);

  // Tarih formatlama fonksiyonu
  const formatDate = (dateString) => {
    const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', options);
  };

  // Bağlantıyı kopyalama fonksiyonu
  const copyLinkToClipboard = (linkName) => {
    const link = `${window.location.origin}/Duyurular/${encodeURIComponent(linkName)}`;
    navigator.clipboard.writeText(link).then(() => {
      toast.success('Bağlantı kopyalandı!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, (err) => {
      console.error('Bağlantı kopyalanamadı: ', err);
    });
  };

  return (
    <div className="container mx-auto px-4 py-32 bg-gray-50">
      <ToastContainer />
      <h1 className="text-4xl font-bold text-blue-900 mb-10 text-center">Duyurular</h1>

      {/* Tarihe Göre Sıralama */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-semibold text-blue-800">Tarihe Göre Sıralama</h2>
        <select
          value={sortOrder}
          onChange={(e) => handleSortChange(e.target.value)}
          className="border border-gray-300 rounded p-2">
          <option value="desc">En Yeni</option>
          <option value="asc">En Eski</option>
        </select>
      </div>

      <div className="space-y-8">
        {currentItems.map((duyuru, index) => (
          <div
            key={index}
            className="bg-white p-6 rounded-lg shadow-md border border-gray-200 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <img src={duyuru.image} alt={duyuru.title} className="w-full md:w-48 h-48 object-cover rounded-lg hidden md:block" />
            <div className="flex flex-col justify-between gap-2">
              <h3 className="text-2xl font-bold text-gray-800">{duyuru.title}</h3>
              <p className="text-blue-600 font-semibold">Tarih: {formatDate(duyuru.date)} - Saat: {duyuru.time}</p>
              <p className="text-gray-700 text-lg">{duyuru.description}</p>
              <p className="text-gray-700">Kayıt için: <a href={`mailto:${duyuru.registration}`} className="text-blue-600 font-semibold">{duyuru.registration}</a></p>
              <p className="text-gray-600 italic">{duyuru.note}</p>
              <div className="flex space-x-4">
                <Link to={`/Duyurular/${duyuru.linkName}`} className="text-white bg-blue-600 hover:bg-blue-700 font-semibold py-2 px-4 rounded-full text-center transition duration-300 ease-in-out transform hover:scale-105 w-32">
                  Daha Fazla
                </Link>
                <button
                  onClick={() => copyLinkToClipboard(duyuru.linkName)}
                  className="text-white bg-green-600 hover:bg-green-700 font-semibold py-2 px-4 rounded-full text-center transition duration-300 ease-in-out transform hover:scale-105 w-44 whitespace-nowrap"
                >
                  Bağlantıyı Paylaş
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex justify-center mt-10">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`px-4 py-2 mx-1 rounded ${currentPage === i + 1 ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'
                } hover:bg-blue-700 transition`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
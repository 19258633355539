import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

export default function İletisim() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Replace with your actual user ID (public key) from EmailJS
    emailjs.sendForm('service_ciy5hoc', 'template_jkkdof2', form.current, 'FIVxED7j-u9c1G6nq')
      .then((result) => {
          console.log(result.text);
          alert('Mesajınız başarıyla gönderildi!');
      }, (error) => {
          console.log(error.text);
          alert('Mesaj gönderilemedi, lütfen tekrar deneyin.');
      });
  };

  return (
    <div className="container mx-auto px-8 py-32">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Form Area */}
        <div className="bg-white p-8 rounded-lg shadow-lg lg:col-span-2">
          <h2 className="text-2xl font-bold mb-6">Bize Ulaşın</h2>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div className="flex gap-4">
              <input
                type="text"
                name="from_name"
                placeholder="Adınız"
                className="border p-2 w-full rounded"
                required
              />
              <input
                type="text"
                name="last_name"
                placeholder="Soyadınız"
                className="border p-2 w-full rounded"
                required
              />
            </div>
            <input
              type="email"
              name="user_email"
              placeholder="E-posta"
              className="border p-2 w-full rounded"
              required
            />
            <textarea
              name="message"
              placeholder="Mesajınız"
              className="border p-2 w-full rounded"
              rows="5"
              required
            ></textarea>
            <div className="flex items-center">
              {/* <input type="checkbox" name="privacy_policy" className="mr-2" required />
              <label htmlFor="privacy_policy" className="text-gray-700">
                Gizlilik Politikamızı kabul ediyorum.
              </label> */}
            </div>
            <button
              type="submit"
              className="bg-black text-white px-4 py-2 rounded w-full hover:bg-gray-800 transition"
            >
              Gönder
            </button>
          </form>
        </div>

        {/* Contact Information Area */}
        <div className="flex flex-col justify-center bg-gray-100 p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6">İletişim Bilgileri</h2>
          <p className="text-gray-600 mb-8">
            Size daha fazla bilgi mi gerekiyor? Lütfen bizimle iletişime geçin.
          </p>
          <div className="space-y-6">
            <div className="flex items-start">
              <div className="text-blue-600 mr-4">
                <i className="fas fa-envelope fa-2x"></i>
              </div>
              <div>
                <h3 className="font-semibold">Adresimiz</h3>
                <p>Çukurova Üniversitesi Tıp Fakültesi 
Tıp Eğitimi ve Bilişimi Ana Bilim Dalı
Balcalı Kampüsü 01250 Sarıçam/Adana, Türkiye</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="text-blue-600 mr-4">
                <i className="fas fa-phone fa-2x"></i>
              </div>
              <div>
                <h3 className="font-semibold">Telefon</h3>
                <p>Telefon: +90 322 338 60 60-61</p>
                <p>Fax: +90 322 338 65 72</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="text-blue-600 mr-4">
                <i className="fas fa-map-marker-alt fa-2x"></i>
              </div>
              <div>
                <h3 className="font-semibold">E-posta</h3>
                <p> krizlervehekimlik@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Map Area */}
      <div className="mt-12">
        <iframe
          title="Çukurova Üniversitesi Haritası"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d815077.4045152415!2d34.135684878124984!3d37.059958800000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15288dd594987ed3%3A0x56ee3443510a3144!2s%C3%87ukurova%20%C3%9Cniversitesi!5e0!3m2!1str!2str!4v1729757449776!5m2!1str!2str"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-lg shadow-lg"
        ></iframe>
      </div>
    </div>
  );
}

import React, { useState } from "react";

export default function OurTeam() {
  const [expanded, setExpanded] = useState({});

  const truncateText = (text, limit) => {
    const words = text.split(" ");
    return words.length > limit
      ? words.slice(0, limit).join(" ") + "..."
      : text;
  };

  const toggleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const categories = [
    {
      title: "Proje Yürütücüsü",
      members: [
        {
          name: "Doç. Dr. Ş. Erhan Bağcı",
          position: "Çukurova Üniversitesi Tıp Fakültesi",
          image: "/images/erhan.JPG",
          description:
            "Çukurova Üniversitesi Tıp Fakültesi’nde öğretim üyesidir. Ankara Üniversitesi Eğitim Bilimleri Fakültesi Psikolojik Danışmanlık ve Rehberlik Bölümü’nden 2002 yılında mezun oldu ve Milli Eğitim Bakanlığı’na bağlı çeşitli okullarda rehber öğretmen olarak çalıştı. Öğretmenliği sırasında yetişkin eğitimi alanında yüksek lisans ve doktora dereceleri ile doçentlik unvanını aldı. Ankara Üniversitesi Hayat Boyu Öğrenme ve Yetişkin Eğitimi Bölümü’nde yüksek lisans ve doktora dersleri verdi. 2015 yılında Almanya’da Hamburg Helmut Schmidt Üniversitesi’nde doktora sonrası araştırmasını tamamladı, 2020 yılında aynı üniversitede lisans ve yüksek lisans düzeylerinde çeşitli dersler verdi. Ardından Maltepe Üniversitesi Eğitim Fakültesi’nde çevrimiçi lisans ve yüksek lisans dersleri yürüttü. 2024 yılında Anadolu Üniversitesi Açıköğretim Fakültesi Fotoğrafçılık ve Kameramanlık önlisans programını tamamladı. Gazi Üniversitesi Sağlık Bilimleri Enstitüsü’nde Tıp Eğitimi doktora programına devam etmektedir. 2019 yılından bu yana Çukurova Üniversitesi Tıp Fakültesi Tıp Eğitimi ve Bilişimi Ana Bilim Dalı Başkanı’dır. ",
        },
      ],
    },
    {
      title: "Araştırmacılar",
      members: [
        {
          name: "Öğr. Gör. Dr. Şengül Erden",
          position: "Ankara Üniversitesi Tıp Fakültesi",
          image: "/images/şengül.jpg",
          description:
            "Ankara Üniversitesi Tıp Fakültesi’nde öğretim görevlisidir. 1996 yılında Hacettepe Üniversitesi Sağlık Hizmetleri Meslek Yüksekokulu Hemşirelik Bölümünden, 2003 yılında Ankara Üniversitesi Dil ve Tarih Coğrafya Fakültesi Dilbilim Bölümü’nden ve 2011 yılında Atatürk Üniversitesi Sağlık Bilimleri Fakültesi Hemşirelik Bölümü’nden mezun oldu. Ankara Üniversitesi Tıp Fakültesi’nde hemşire olarak çalıştı. Ankara Üniversitesi Eğitim Bilimleri Enstitüsü Hayat Boyu Öğrenme ve Yetişkin Eğitimi Bilim Dalı’nda yetişkin eğitimi alanında yüksek lisans ve doktora eğitimini tamamladı. Ankara Üniversitesi Sağlık Bilimleri Enstitüsü’nde Tıp Eğitimi yüksek lisans programına devam etmektedir. 2021 yılından bu yana Ankara Üniversitesi Tıp Fakültesi Tıp Eğitimi ve Bilişimi Anabilim Dalı’nda Öğr. Gör. Dr. olarak çalışmaktadır.",
        },
        {
          name: "Öğr. Gör. Dr. Çağla Okyar",
          position: "Çukurova Üniversitesi Tıp Fakültesi",
          image: "/images/çağla.jpg",
          description:
            "Çukurova Üniversitesi Tıp Fakültesi’nde öğretim görevlisidir. 2015 yılında Çukurova Üniversitesi Tıp Fakültesi’nden mezun oldu. Mecburi hizmet için atandığı Gaziantep’te 2015-2022 yılları arasında acil servis, toplum sağlığı merkezi, aile hekimliği birimlerinde çalıştı. Akdeniz Üniversitesi Sağlık Bilimleri Enstitüsü Tıp Eğitimi Doktora programında eğitimine devam etmektedir. 2022 yılından bu yana Çukurova Üniversitesi Tıp Fakültesi Tıp Eğitimi ve Bilişimi Anabilim Dalı’nda öğretim görevlisi doktor olarak çalışmaktadır. Türk Tabipleri Birliği Afetlerde Sağlık Hizmetleri Yönetim Akademisi’nde afet eğitimleri komisyonu üyesi olarak görev yapmaktadır. İstanbul Üniversitesi Açık ve Uzaktan Eğitim Fakültesi Sosyoloji lisans programına devam etmektedir.",
        },
        {
          name: "Öğr. Gör. Aslı Boz",
          position: "Çukurova Üniversitesi Tıp Fakültesi",
          image: "/images/aslı.jpg",
          description:
            "Çukurova Üniversitesi (ÇÜ) Tıp Fakültesi’nde Öğretim Görevlisi’dir. 2013 yılında Çukurova Üniversitesi Fen Edebiyat Fakültesi İstatistik Bölümü’nden mezun oldu. Aynı yıl İktisadi ve İdari Bilimler Fakültesi Ekonometri Anabilim Dalı’nda çift anadalını tamamladı. 2018 yılında ÇÜ Sosyal Bilimler Enstitüsü Ekonometri ve 2019 yılında ÇÜ Sağlık Bilimleri Enstitüsü Biyoistatistik yüksek lisans programlarını tamamladı. Akdeniz Üniversitesi Sağlık Bilimleri Enstitüsü Tıp Bilişimi doktora programında eğitimine devam etmektedir. 2019 yılından bu yana ÇÜ Tıp Fakültesi Tıp Eğitimi ve Bilişimi Anabilim Dalı’nda Öğretim Görevlisi olarak çalışmaktadır.",
        },
      ],
    },
    {
      title: "Danışmanlar",
      members: [
        {
          name: "Prof. Dr. Gülşah Seydaoğlu",
          position: "Çukurova Üniversitesi Tıp Fakültesi",
          image: "/images/gülşah.jpg",
          description:
            "Çukurova Üniversitesi Tıp Fakültesi’nde öğretim üyesidir. İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi’nden 1986 yılında mezun oldu. 1997 yılında Çukurova Üniversitesi Sağlık Bilimleri Enstitüsü Biyoistatistik yüksek lisans, 2003 yılında yine Çukurova Üniversitesi Sağlık Bilimleri Enstitüsü Biyoistatistik doktora programından mezun oldu. 2006 yılında doçentlik unvanını aldı. 1995 yılında Araştırma Görevlisi olarak çalışmaya başladığı Çukurova Üniversitesi Tıp Fakültesi Biyoistatistik Anabilim Dalı’nda 2013’ten bu yana profesör olarak görevine devam etmektedir. ",
        },
        {
          name: "Prof. Dr. Özlem Coşkun",
          position: "Gazi Üniversitesi Tıp Fakültesi",
          image: "/images/özlem.jpg",
          description:
            "Gazi Üniversitesi Tıp Fakültesi’nde öğretim üyesidir. Ankara Üniversitesi Tıp Fakültesi’nden 1989 yılında mezun oldu. 2006 yılında Ankara Üniversitesi Sağlık Bilimleri Enstitüsü’nde Sosyal Psikiyatri yüksek lisans, 2012 yılında yine Ankara Üniversitesi Sağlık Bilimleri Enstitüsü’nde İş Sağlığı doktora programından mezun oldu. 2020 yılında doçentlik, 2024 yılında profesörlük unvanlarını aldı. 2007 yılında öğretim görevlisi olarak çalışmaya başladığı Gazi Üniversitesi Tıp Fakültesi Tıp Eğitimi ve Bilişimi Anabilim Dalı’nda görevine devam etmektedir. ",
        },
        {
          name: "Prof. Dr. Işıl İrem Budakoğlu",
          position: "Gazi Üniversitesi Tıp Fakültesi",
          image: "/images/irem.JPG",
          description:
            "Gazi Üniversitesi Tıp Fakültesi’nde öğretim üyesidir. Gazi Üniversitesi Tıp Fakültesi’nden 1998 yılında mezun oldu. 2003 yılında Gazi Üniversitesi Halk Sağlığı Anabilim Dalı’nda uzmanlık eğitimini tamamladı. 2011 yılında Ankara Üniversitesi’nde Tıp Eğitimi yüksek lisans programından mezun oldu. 2014 yılında profesörlük unvanını aldı. Gazi Üniversitesi Tıp Fakültesi Tıp Eğitimi ve Bilişimi Anabilim Dalı’nda anabilim dalı başkanı olarak görevine devam etmektedir.  ",
        },
        {
          name: "Prof. Dr. Meral Atıcı",
          position: "Çukurova Üniversitesi Eğitim Fakültesi",
          image: "/images/meral.JPG",
          description:
            "Lisans eğitimini Ankara Üniversitesi Psikoloji bölümü, Yüksek Lisans eğitimini Ankara Üniversitesi Eğitimde Psikolojik Hizmetler Anabilim dalı ve doktora eğitimini ise İngiltere’deki Leicester Üniversitesi Eğitim Fakültesinde tamamlamıştır. 1987-1989 yılları arasında Ankara Üniversitesi Mediko Sosyal Merkezinde psikolog olarak çalışan Atıcı, Ocak 1990 da ÇÜ Eğitim Fakültesi PDR Anabilim Dalında araştırma görevlisi olarak göreve başlamış; halen aynı Ana Bilim Dalında görev yapmaktadır. ÇÜ PDR Anabilim dalında lisans, yüksek lisans ve doktora düzeyinde dersler yürüten Atıcı, okulda davranış yönetimi, çatışma çözme, psikolojik danışman eğitimi, psikolojik danışmanların mesleki kimlik gelişimi, gelişim psikolojisi ve okulda travma gibi konularda makale, kitap ve kitap bölümü yazarlığı yapmış ve Kognitif Davranış Terapileri Eğitimi ve Süpervizyonunu; Kabul Kararlılık Terapisi başlangıç, kaygı ve depresyon, travma, kayıp ve yas eğitimlerini tamamlamıştır. ",
        },
        {
          name: "Doç. Dr. Mükerrem Akbulut Taş",
          position: "Çukurova Üniversitesi Eğitim Fakültesi",
          image: "/images/mükerrem.jpg",
          description:
            "Çukurova Üniversitesi Eğitim Fakültesi Eğitim Bilimleri Bölümü Eğitim Programları ve Öğretim Ana Bilim Dalı’nda öğretim üyesidir. Çukurova Üniversitesi Eğitim Fakültesi Eğitim Bilimleri Eğitim Programları ve Öğretim Bölümü’nden 2000 yılında mezun oldu. Aynı üniversiteden 2004 yılında Eğitim Bilimleri Eğitim Programları ve Öğretim Ana Bilim Dalı’nda yüksek lisansını, 2010 yılında da Eğitim Bilimleri Eğitim Programları ve Öğretim Ana Bilim Dalı’nda doktora derecesini aldı. 2022 yılında Eğitim Programları ve Öğretim alanından doçentlik unvanını aldı. 1993-2002 yılları arasında hemşire olarak görev yaptı.  2002- 2010 yılları arasında ise Çukurova Üniversitesi Sosyal Bilimler Enstitüsü Eğitim Bilimleri, Eğitim Programları ve Öğretim Ana Bilim Dalı’nda araştırma görevlisi olarak görev yaptı. Halen aynı bölümde öğretim üyesi olarak görev yapmaktadır. 2022 yılından bu yana Çukurova Üniversitesi Eğitim Fakültesi Eğitim Programları ve Öğretim Ana Bilim Dalı Başkanlığı görevini yürütmektedir. ",
        },
      ],
    },
    {
      title: "Bursiyerler",
      members: [
        {
          name: "Derya Yıldırım",
          position: "Doktora Öğrencisi / Milli Eğitim Bakanlığı",
          image: "/images/Derya.jpg",
          description:
            "2007 yılında Dokuz Eylül Üniversitesi Rehberlik ve Psikolojik Danışmanlık lisans programından mezun olmuştur. İngilizce dil eğitimini New York Manhattan Adelphi Üniversitesi ELS Dil okulunda tamamladıktan sonra Montana Üniversitesi’nden yüksek lisans dersleri almıştır. Çukurova Üniversitesi Eğitim Fakültesi Rehberlik ve Psikolojik Danışmanlık Anabilim Dalı’nda kapsamlı cinsellik eğitimleri alanında gerçekleştirdiği tez çalışmasıyla yüksek lisansını tamamlamıştır. Doktora eğitimine, yine Çukurova Üniversitesi Eğitim Fakültesi Rehberlik ve Psikolojik Danışmanlık Anabilim Dalı’nda devam etmektedir. Avusturya Viyana Sigmund Freud Üniversitesi’nde psikanaliz ve sistemik aile terapisi alanında dersler almıştır. Gönüllü olarak 2015 yılında Diyarbakır Yenişehir Fidanlık Kampında Ezidi göçmen çocuklarla grup etkinlikleri gerçekleştirmiş, yine gönüllü olarak 2019 yılında Viyana Caritas (Betreuungszentrum Zweite Gruft) yardım kuruluşunda çalışmıştır. Aile ve Evlilik Terapisi alanında eğitimler almış ve süpervizyon sürecini tamamlamıştır, Bilişsel Davranışçı Terapiler alanında süpervizyon öğrencisidir. Cinsel Eğitim Tedavi ve Araştırma Derneği’nin (CETAD) Cinsellik ve Cinsel İşlev Bozuklukları Temel Eğitimi’ni tamamlamıştır. Toplumsal cinsiyet, dezavantajlı gruplar ve kapsamlı cinsel eğitim alanında çalışmalarına devam etmektedir.",
        },
        {
          name: "Durmuş Ziya Görür",
          position: "Doktora Öğrencisi / Milli Eğitim Bakanlığı",
          image: "/images/ziya.jpg",
          description:
            "2018 yılından bu yana Milli Eğitim Bakanlığı’nda Bilişim Teknolojileri ve Yazılım dersi öğretmeni olarak görev yapmaktadır. Lisans eğitimini Dokuz Eylül Üniversitesi, Eğitim Fakültesi, Bilgisayar ve Öğretim Teknolojileri Eğitimi bölümünde; yüksek lisans eğitimini Ankara Üniversitesi Eğitim Bilimleri Enstitüsü, Eğitim Programları ve Öğretim bölümünde tamamlamıştır. Şu an Çukurova Üniversitesi, Sosyal Bilimler Enstitüsü, Eğitim Programları ve Öğretimi bölümünde doktora eğitimine devam etmektedir.",
        },
      ],
    },
  ];

  return (
    <div>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:px-6">
          {categories.map((category, catIndex) => (
            <div key={catIndex}>
              <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  {category.title}
                </h2>
              </div>
              <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                {category.members.map((teamMember, index) => (
                  <div
                    key={index}
                    className="flex flex-col sm:flex-row items-center bg-gray-50 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 p-4"
                  >
                    <a href="/" className="flex-shrink-0">
                      <img
                        className="w-32 h-32 md:w-40 md:h-40 rounded-full object-fill mx-auto sm:mx-0"
                        src={teamMember.image}
                        alt={teamMember.name}
                      />
                    </a>
                    <div className="p-5">
                      <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                        <a href="/">{teamMember.name}</a>
                      </h3>
                      <span className="text-gray-500 dark:text-gray-400">
                        {teamMember.position}
                      </span>
                      <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">
                        {expanded[`${catIndex}-${index}`]
                          ? teamMember.description
                          : truncateText(teamMember.description, 30)}
                      </p>
                      <button
                        className="text-blue-600 dark:text-blue-400 hover:underline"
                        onClick={() =>
                          toggleExpand(`${catIndex}-${index}`)
                        }
                      >
                        {expanded[`${catIndex}-${index}`]
                          ? "Kapat"
                          : "Devamını Oku"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import '../Calender.css';

const CalendarSlider = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Duyurular verisini Duyurular.json dosyasından yükle
    fetch(`${process.env.PUBLIC_URL}/Duyurular.json`)
      .then((response) => response.json())
      .then((data) => {
        setAnnouncements(data);
      })
      .catch((error) => console.error('Error fetching announcements:', error));
  }, []);

  useEffect(() => {
    // Otomatik geçiş (Auto-slide) ayarı
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000);

    return () => clearInterval(slideInterval);
  }, [announcements]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? announcements.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
    );
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('tr-TR', options);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-8 py-4 px-4">
      {/* Slider Başlığı */}
      <h2 className="text-2xl font-bold text-blue-800 mb-4">Duyurular</h2>

      {/* Slider Bölümü */}
      <div className="relative w-full max-w-lg">
        <div className="flex items-center justify-between">
          <button onClick={handlePrev} className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 transition">
            ❮
          </button>

          <div className="flex-1 mx-4 overflow-hidden">
            <div
              className="flex transition-transform duration-500"
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              {announcements.length > 0 ? (
                announcements.map((announcement, index) => (
                  <div
                    key={index}
                    className="w-full flex-shrink-0 p-4 bg-white shadow-lg rounded-lg"
                    style={{ minWidth: '100%' }}
                  >
                    <img src={announcement.image} alt={announcement.title} className="w-full h-48 object-fit rounded-lg mb-4" />
                    <h3 className="text-xl font-semibold mb-2">{announcement.title}</h3>
                    <h6 className='pb-2'>Çevrimiçi Toplantı</h6>
                    <p className="text-gray-700 mb-2 text-md">{announcement.description}</p>
                    <p className="text-blue-400 text-lg mb-2 font-bold">Tarih: {formatDate(announcement.date)}  </p>
                    <p className="text-blue-400 text-lg font-bold mb-2">Saat: {announcement.time}</p>
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                      <a href={`/Duyurular/${announcement.linkName}`} className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 text-center">
                        Daha fazla
                      </a>
                      <a href={`/Duyurular/${announcement.linkName}`} className="px-4 py-2 bg-green-500 text-white rounded-full hover:bg-green-600 text-center">
                        Kayıt ol
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-4 bg-white shadow-lg rounded-lg text-center flex justify-center">
                  <p>Şu anda gösterilecek duyuru bulunmamaktadır.</p>
                </div>
              )}
            </div>
          </div>

          <button onClick={handleNext} className="p-2 bg-gray-300 rounded-full hover:bg-gray-400 transition">
            ❯
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarSlider;